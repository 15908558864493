import './Base.css';
import Home from '../components/Home';
import Guzheng from '../components/Guzheng';
import Workout from '../components/Workout';
import Motorcycle from '../components/Motorcycle';
import Portfolio from '../components/Portfolio';
import Garden from '../components/Garden';

import WaveSVG from '../assets/bottom_wave.svg';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";


function Base() {
  return (
    <div className="App">
      <header className="main-container">
      <Router>
        <Link  to="/">
          <div style={{display: 'flex', justifyContent:'center'}}>
          <p className='title'>nics.place</p>
          </div>
        </Link>
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/portfolio" element={<Portfolio />} />
            <Route path="/garden" element={<Garden />} />
            <Route path="/guzheng" element={<Guzheng />} />
            <Route path="/motorcycle" element={<Motorcycle />} />
            <Route path="/workout" element={<Workout />} />
        </Routes>
        </Router>
        <div className='wave-container'>
          <img className="wave" src={WaveSVG} alt="bottom-wave" />
        </div>
        <div id="footer"><p>(c) nic marchini '22</p></div>
      </header>
    </div>
  );
}

export default Base;
