import './Modal.css';
import React from 'react';

function Modal(props) {

  return (
    <>
        { props.modal && (
            <div className="modal">
            <div onClick={props.toggleModal} className="overlay">
                <div className="modal-content">
                    <img className="modal-image" src={props.img} alt="testy"></img>
                </div>
            </div>
        </div>
        )}
    </>
  );
}

export default Modal;
