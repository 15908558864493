import './CardGrid.css';
import React from 'react';
import { Container, useScreenClass } from 'react-grid-system';
import Card from '../components/Card.js';

function CardGrid(props) {
  const gridSize = "grid-container " + useScreenClass();
 
  var settings = props.cardsProp
  var cards = []

  if (settings != null){
    cards = settings.filter((card) => 
      (card.props.title.toLowerCase()+card.props.description.toLowerCase()).includes(props.searchProp)).map((card) => { 
        return (<Card {...card.props} 
                      key={card.props.title} />)}
                )
  }

  return (
    <Container>
     <div className={gridSize}>
       { cards }
     </div>
   </Container>  
  );
  }
  
export default CardGrid;