import './Search.css'

function Search(props) {
    return (
    <div>
        {/* <label for="site-search">Search the site:</label> */}
        <input type="search" id="searchbar" placeholder='search an image...' value={props.value} onClick={props.onClick} onChange={props.onChange} ></input>
    </div>
    )
}

export default Search;