import './Workout.css';

function Workout() {
  return (
    <div className='main-container'> 
        <p className='pagename'>workout</p>
    </div>
  );
}

export default Workout;
