import './Motorcycle.css';

function Motorcycle() {
  return (
    <div className='main-container'> 
      <p className='pagename'>motorcycle</p>
    </div>
  );
}

export default Motorcycle;
