import './Portfolio.css';

function Portfolio() {
  return (
    <div className='main-container'> 
      <p className='pagename'>portfolio</p>
      <div className='scrollable'>
        test
      </div>
    </div>
  );
}

export default Portfolio;
