import './Home.css';
import SquareSVG from '../assets/square.svg';
import StarSVG from '../assets/star.svg';
import TriangleSVG from '../assets/triangle.svg';
import RhombusSVG from '../assets/rhombus.svg';
import CircleSVG from '../assets/circle.svg';

import { Link } from "react-router-dom";

function Home() {
  return (
    <div className='main-container'> 
        <p className='pagename'>home</p>
        <div className="choice-container">
            <Link className="choice" to="/portfolio">
                <img className="choice-pic" src={SquareSVG} alt="testy" />
                <p className='choice-label'>portfolio</p>
            </Link>
            <Link className="choice" to="/workout">
                <img className="choice-pic" src={StarSVG} alt="testy" />
                <p className='choice-label'>workout</p>
            </Link>
            <Link className="choice" to="/guzheng">
                <img className="choice-pic" src={TriangleSVG} alt="testy" />
                <p className='choice-label'>guzheng</p>
            </Link>
            <Link className="choice" to="/garden">
                <img className="choice-pic" src={RhombusSVG} alt="testy" />
                <p className='choice-label'>garden</p>
            </Link>
            <Link className="choice" to="/motorcycle">
                <img className="choice-pic" src={CircleSVG} alt="testy" />
                <p className='choice-label'>motorcycle</p>
            </Link>
        </div>
    </div>
  );
}

export default Home;
