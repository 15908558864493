import './Card.css';
import React, { useState } from 'react';
import Modal from './Modal';

function Card(props){

    const screenClass = 'card'

    const [modal, setModal] = useState(false);

    const toggleModal = () => {
        setModal(!modal);
        console.log(modal)
    }

    return (
        <div  onClick={toggleModal} className={screenClass}>
        <img  id='cardicon' src={props.img} alt="testy"></img>
        <Modal modal={modal} toggleModal={toggleModal} img={props.img}></Modal>
        <p className="cardtitle">{props.title}</p>
        </div>
    )
}

export default Card;