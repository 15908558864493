import './Guzheng.css';

function Guzheng() {
  return (
    <div className='main-container'> 
      <p className='pagename'>guzheng</p>
    </div>
  );
}

export default Guzheng;
